/* eslint-disable no-underscore-dangle */
import { Fragment } from 'react';
import type { ReactElement } from 'react';
import type { Slot } from '~/amplienceTypes/content';
import { Banner as PDPBanner } from '../../components/pdp/banner/Banner';
import { MarketingComponents } from '~/components/pdp/marketingComponents/MarketingComponents';
import { ProductImageGallery } from '~/components/pdp/productDetail/productImageGallery/ProductImageGallery';
import { ProductAccordions } from '~/components/pdp/productAccordions/ProductAccordions';
import { ProductDetailInfo } from '~/components/pdp/productDetail/ProductDetailInfo';
import { ExplodedView } from '~/components/pdp/explodedView/ExplodedView';
import { ProductSizeFit } from '~/components/pdp/productSizeFit/ProductSizeFit';
import { ProductDescriptionCare } from '~/components/pdp/productDescriptionCare/ProductDescriptionCare';
import { ProductSustainabilityTraceability } from '~/components/pdp/sustainabilityTraceability/ProductSustainabilityTraceability';
import { ProductDeliveryAndReturns } from '~/components/pdp/deliveryAndReturns/ProductDeliveryAndReturns';
import { FiberRanking } from '~/components/pdp/fiberRanking/FiberRanking';
import { Recommendations } from '~/components/shared/recommendations/Recommendations';
import { Container, Row, Column } from '~/components/shared/core/styles';
import { PageNotFound } from '~/components/shared/pageNotFound/PageNotFound';
import { ServerError } from '~/components/shared/serverError/ServerError';
import { HtmlCode } from '~/components/unmanaged/external/HtmlCode';
import { Banner as UnmanagedBanner } from '../../components/unmanaged/templates/Banner';
import { ProductInfo } from '~/components/pdp/productInfo/ProductInfo';
import { Section } from '~/components/unmanaged/templates/section/Section';
import { SectionDownloads } from '~/components/unmanaged/templates/SectionDownloads';
import type { LocalizedSlot } from '~/amplienceTypes/schemas/exported/localized-slot-schema';
import { JavascriptCode } from '~/components/unmanaged/external/JavascriptCode';

type SchemaComponent = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [k: string]: (props: any) => ReactElement;
};

export const unmanagedComponents: SchemaComponent = {
  'https://ahc.g-star.com/content/html-code.json': HtmlCode,
  'https://ahc.g-star.com/content/javascript-code.json': JavascriptCode,
  'https://ahc.g-star.com/content/simple-video-banner.json': UnmanagedBanner,
  'https://ahc.g-star.com/content/simple-banner.json': UnmanagedBanner,
  'https://ahc.g-star.com/content/banner.json': UnmanagedBanner,
  'https://ahc.g-star.com/content/localized-banner.json': UnmanagedBanner,
  'https://ahc.g-star.com/content/logo-banner.json': UnmanagedBanner,
  'https://ahc.g-star.com/content/hotspot-banner.json': UnmanagedBanner,
  'https://ahc.g-star.com/content/section.json': Section,
  'https://ahc.g-star.com/content/localized-section.json': Section,
  'https://ahc.g-star.com/content/section-downloads.json': SectionDownloads,
  'https://ahc.g-star.com/content/product-recommendations.json': Recommendations,
};

export const managedComponents: SchemaComponent = {
  // PDP
  'https://ahc.g-star.com/content/product-image-gallery.json': ProductImageGallery,
  'https://ahc.g-star.com/content/product-info.json': ProductInfo,
  'https://ahc.g-star.com/content/product-size-fit.json': ProductSizeFit,
  'https://ahc.g-star.com/content/product-description-care.json': ProductDescriptionCare,
  'https://ahc.g-star.com/content/product-sustainability-traceability.json':
    ProductSustainabilityTraceability,
  'https://ahc.g-star.com/content/product-delivery-and-returns.json': ProductDeliveryAndReturns,
  'https://ahc.g-star.com/content/pdp-banner.json': PDPBanner,
  'https://ahc.g-star.com/content/product-recommendations.json': Recommendations,
  'https://ahc.g-star.com/content/product-detail-info.json': ProductDetailInfo,
  'https://ahc.g-star.com/content/product-exploded-view.json': ExplodedView,
  'https://ahc.g-star.com/content/product-fiber-ranking.json': FiberRanking,
  'https://ahc.g-star.com/content/pdp-marketing-components.json': MarketingComponents,

  // Others
  'https://ahc.g-star.com/content/global-404.json': PageNotFound,
  'https://ahc.g-star.com/content/global-500.json': ServerError,
};

export const showComponent = (slot: Slot, key: number, children?: ReactElement[]): ReactElement => {
  /* eslint-disable-next-line no-underscore-dangle */
  const schema = slot.properties?.content?._meta.schema;
  const Component = schema ? managedComponents[schema] : null;

  switch (slot.name) {
    case 'container':
      return <Container key={key}>{!!children && children}</Container>;
    case 'row':
      return (
        <Row key={key} {...slot.properties}>
          {!!children && children}
        </Row>
      );
    case 'column':
      return (
        <Column key={key} {...slot.properties}>
          {!!children && children}
        </Column>
      );
    case 'productAccordions':
      return (
        <ProductAccordions key={key} name={slot.name} {...slot.properties}>
          {!!children && children}
        </ProductAccordions>
      );
    // Will add component names continuously here for example,  case 'productInfo' || 'newsletter'
    case 'productInfo':
    case 'banner':
    case 'recommendations':
    case 'productImageGallery':
    case 'productDetailInfo':
    case 'productExplodedView':
    case 'productSizeFit':
    case 'productDescriptionCare':
    case 'productSustainabilityTraceability':
    case 'productDeliveryAndReturns':
    case 'fiberRanking':
    case 'marketingComponents':
    case '404':
    case '500': {
      return Component ? (
        <Component key={key} {...slot.properties?.content} />
      ) : (
        <Fragment key={key} />
      );
    }
    default:
      return <Fragment key={key} />;
  }
};

export const showLayoutContent = (slots: Slot[] | null | undefined): ReactElement[] => {
  const result: ReactElement[] = [];

  slots?.forEach((slot, i) => {
    if (slot.slots) {
      result.push(showComponent(slot, i, showLayoutContent(slot.slots.children)));
    } else {
      result.push(showComponent(slot, i));
    }
  });

  return result;
};

export const showLayoutContentForHomepage = (homepageSlotContent: LocalizedSlot) =>
  homepageSlotContent.content?.map((content, index) => {
    const Component = unmanagedComponents[content._meta.schema];

    return Component ? <Component key={index} {...content} /> : <Fragment key={index} />;
  });
