import type { ReactElement } from 'react';
import styled from 'styled-components';
import { media, colors } from '../../../shared/core/styles';

const S = {
  PlayButton: styled.div`
    position: relative;
    display: block;
    left: 0;
    right: 0;
    bottom: 0;
    width: 40px;
    height: 40px;
    margin: auto;
    color: ${colors.NERO_GREY};
    overflow: visible;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      height: 0;
      width: 0;
      top: 50%;
      left: 50%;
      border-bottom: 8px solid transparent;
      border-left: 13.6px solid ${colors.NERO_GREY};
      border-top: 8px solid transparent;
      margin-top: -8px;
      margin-left: -4.8px;
      transition: transform 0.3s ease-in-out 0.06s;

      @media (prefers-reduced-motion) {
        transition: none;
      }
    }

    &::after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 2px solid ${colors.NERO_GREY};
      border-radius: 50%;
      transition: transform 0.25s ease-in-out;
      backface-visibility: hidden;
      box-sizing: border-box;

      @media (prefers-reduced-motion) {
        transition: none;
      }
    }

    &:hover,
    &:focus {
      &::before {
        transform: scale(1.1);
      }

      &::after {
        transform: scale(1.05);
      }
    }

    @media ${({ theme }) => media(theme).greaterThan('xl')} {
      width: 50px;
      height: 50px;

      &::before {
        border-bottom-width: 10px;
        border-left-width: 17px;
        border-top-width: 10px;
        margin-top: -10px;
        margin-left: -6px;
      }
    }
  `,
  Label: styled.div`
    transform: translate(0, 0);
    margin-top: 0.1em;
    color: ${colors.NERO_GREY};
    font-size: 11px;
    font-weight: 700;
  `,
};

interface ProductVideoPlayButtonProps {
  onClick: () => void;
}

export const ProductVideoPlayButton = ({ onClick }: ProductVideoPlayButtonProps): ReactElement => (
  <>
    <S.PlayButton onClick={onClick} data-testid="video-thumbnail" />
    <S.Label>video</S.Label>
  </>
);
