import type { ReactElement, MouseEventHandler } from 'react';
import styled, { css } from 'styled-components';
import { media, colors } from '../../../shared/core/styles';
import { SiteType } from '~/utilities/graphql/codegen';

interface DotNavigationProps {
  galleryZoomIn: boolean;
  currentImageIndex: number;
  clickHandlers: MouseEventHandler<HTMLElement>[];
}

const aafDotNavigationStyle = css`
  bottom: 16px;
  left: 0;
  right: 0;
  margin-inline: auto;
  width: fit-content;
`;

const S = {
  GridCarouselNavigation: styled.div<{
    $galleryZoomIn: boolean;
    $currentImageIndex: number;
  }>`
    position: absolute;
    left: 10px;
    top: ${({ theme }) => theme.siteType !== SiteType.Aaf && '10px'};
    ${({ theme }) => theme.siteType === SiteType.Aaf && aafDotNavigationStyle}
    z-index: ${({ $galleryZoomIn }) => ($galleryZoomIn ? 0 : 2)};

    button {
      display: inline-block;
      cursor: pointer;
      width: 12px;
      height: 12px;
      position: relative;
      border: none;

      &::before {
        position: absolute;
        left: 50%;
        top: 50%;
        border-radius: 50%;
        margin-top: -3px;
        margin-left: -3px;
        background: ${({ theme }) => theme.colors.content.component['dot-navigation-button']};
        content: '';
        display: block;
        height: 6px;
        width: 6px;
      }
    }

    button:nth-child(${({ $currentImageIndex }) => $currentImageIndex + 1}) {
      &::before {
        background: ${colors.ACCESSIBILITY_GREY};
      }
    }

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      display: none;
    }
  `,
};

export const DotNavigation = ({
  galleryZoomIn,
  currentImageIndex,
  clickHandlers,
}: DotNavigationProps): ReactElement => (
  <S.GridCarouselNavigation
    data-testid="image-gallery-navigation"
    $galleryZoomIn={galleryZoomIn || false}
    $currentImageIndex={currentImageIndex}
  >
    {clickHandlers.map((handler, index) => (
      <button aria-label={`Image #${index + 1}`} key={index} onClick={handler} type="button" />
    ))}
  </S.GridCarouselNavigation>
);
