import styled from 'styled-components';
import { Typography } from '../../shared/core/typography/Typography';

export const S = {
  AccordionTitle: styled(Typography)`
    padding-bottom: 9px;
  `,

  Container: styled.div`
    ul {
      margin: 0 0 13px 16px;
      padding-left: 0;
    }

    a,
    a:hover,
    a:focus,
    a:visited {
      text-decoration: underline;
    }
  `,
};
