import { useCallback, useEffect, useRef, useState } from 'react';
import type { ReactElement } from 'react';
import type { RuleSet } from 'styled-components';
import styled, { css } from 'styled-components';
import { useStaticContext } from '../../../../utilities/context/static/StaticContext';
import { useMediaQueryContext } from '../../../../utilities/context/dynamic/MediaQueryContext';
import { useAppContext } from '../../../../utilities/context/static/AppContext';
import { usePDPContext } from '../../../../utilities/context/static/PDPContext';
import { useSizeContext } from '../../../../utilities/context/dynamic/SizeContext';
import { colors, media } from '../../../shared/core/styles';
import { SiteType, SizeType, useUspsQuery } from '../../../../utilities/graphql/codegen';
import { Dialog } from '../../../shared/core/dialog/Dialog';
import { usePDPDynamicContext } from '../../../../utilities/context/dynamic/PDPDynamicContext';

const GstarContentUspDeliveryStyle = css`
  content: '\\F1BD';
  font-size: ${({ theme }) => theme.font.size[4]};
`;

const AafContentUspDeliveryStyle = css`
  content: '\\F26A';
  font-size: 12px;
`;

const GstarContentUspLocationStyle = css`
  content: '\\F1BE';
  font-size: ${({ theme }) => theme.font.size[4]};
`;

const AafContentUspLocationStyle = css`
  content: '\\F26B';
`;

const GstarContentUspSameDayDispatchStyle = css`
  content: '\\F1C2';
  font-size: ${({ theme }) => theme.font.size[4]};
`;

const AafContentUspSameDayDispatchStyle = css`
  content: '\\F26C';
`;

const GstarContentUspItemStyle = css`
  color: ${({ theme }) => theme.colors.content.primary};
  line-height: 1.4;
  padding-left: ${({ theme }) => theme.padding.md};
`;

const AafContentUspItemStyle = css``;

const GstarContentUspItemTitleStyle = css`
  color: ${({ theme }) => theme.colors.content.primary};
  line-height: 1.4;
`;

const AafContentUspItemTitleStyle = css``;

export const ContentUspDeliveryConfig: Record<string, RuleSet> = {
  [SiteType.Gstar]: GstarContentUspDeliveryStyle,
  [SiteType.Outlet]: GstarContentUspDeliveryStyle,
  [SiteType.Employeeshop]: GstarContentUspDeliveryStyle,
  [SiteType.Aaf]: AafContentUspDeliveryStyle,
};

export const ContentUspLocationConfig: Record<string, RuleSet> = {
  [SiteType.Gstar]: GstarContentUspLocationStyle,
  [SiteType.Outlet]: GstarContentUspLocationStyle,
  [SiteType.Employeeshop]: GstarContentUspLocationStyle,
  [SiteType.Aaf]: AafContentUspLocationStyle,
};

export const ContentUspSameDayDispatchConfig: Record<string, RuleSet> = {
  [SiteType.Gstar]: GstarContentUspSameDayDispatchStyle,
  [SiteType.Outlet]: GstarContentUspSameDayDispatchStyle,
  [SiteType.Employeeshop]: GstarContentUspSameDayDispatchStyle,
  [SiteType.Aaf]: AafContentUspSameDayDispatchStyle,
};

export const ContentUspItemConfig: Record<string, RuleSet> = {
  [SiteType.Gstar]: GstarContentUspItemStyle,
  [SiteType.Outlet]: GstarContentUspItemStyle,
  [SiteType.Employeeshop]: GstarContentUspItemStyle,
  [SiteType.Aaf]: AafContentUspItemStyle,
};
export const ContentUspItemTitleConfig: Record<string, RuleSet> = {
  [SiteType.Gstar]: GstarContentUspItemTitleStyle,
  [SiteType.Outlet]: GstarContentUspItemTitleStyle,
  [SiteType.Employeeshop]: GstarContentUspItemTitleStyle,
  [SiteType.Aaf]: AafContentUspItemTitleStyle,
};

const S = {
  Wrapper: styled.div`
    .usp-container {
      position: relative;
    }

    .usp-list {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    .usp-item {
      position: relative;
      padding-left: ${({ theme }) => theme.padding.sm};
      font-size: ${({ theme }) => theme.font.size[2]};
      line-height: 16px;
      ${({ theme }) => ContentUspItemConfig[theme.siteType]}

      &:not(:last-child) {
        padding-bottom: ${({ theme }) => theme.padding.sm};
      }
    }

    .usp-item--image-icon {
      padding-left: 2px;

      & > img {
        margin: 0 0.2em;
        width: 1em;
        height: 1em;
        font-size: 16px;
      }
    }

    .usp-icon {
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        color: ${({ theme }) => theme.colors.content.primary};
        width: 0;
        font-size: ${({ theme }) => theme.font.size[4]};
        margin: 0;
      }
    }

    .usp-item-title {
      color: ${({ theme }) => theme.colors.content.primary};
      font-weight: 400;
    }

    .usp-item-description {
      color: ${({ theme }) => theme.colors.content.primary};
    }

    .icon-infocircle::before {
      content: '\\F155';
      margin: 0 0.2em;
    }

    .icon-usp-account::before {
      content: '\\F1BC';
    }

    .icon-usp-delivery::before {
      ${({ theme }) => ContentUspDeliveryConfig[theme.siteType]}
    }

    .icon-usp-location::before {
      ${({ theme }) => ContentUspLocationConfig[theme.siteType]}
    }

    .icon-usp-ordering::before {
      content: '\\F1BF';
    }

    .icon-usp-payment::before {
      content: '\\F1C0';
    }

    .icon-usp-pickup-point::before {
      content: '\\F1C1';
    }

    .icon-usp-same-day-dispatch::before {
      ${({ theme }) => ContentUspSameDayDispatchConfig[theme.siteType]}
    }

    .icon-usp-worldwide::before {
      content: '\\F1C3';
    }

    .icon-close-lean::before {
      content: '\\F13E';
    }

    .icon-cycle::before {
      content: '\\F145';
    }

    .infoTip {
      width: 400px;
      margin: 0;
      font-style: normal;
      box-shadow: 10px 10px 140px -20px rgba(0 0 0 / 25%);
      position: absolute;
      left: 50%;
      left: -171px;
      bottom: 40px;
      transform: translateX(-50%);

      &__content {
        width: auto;
        min-width: auto;
        padding: 30px;
        background: white;
        font-style: normal;
        font-size: ${({ theme }) => theme.font.size[2]};
        color: ${colors.ACCESSIBILITY_GREY};

        &-title {
          font-weight: 900;
          margin-bottom: 16px;
        }

        &-text {
          color: ${({ theme }) => theme.colors.content.primary};
          font-weight: 700;
          display: block;
        }

        &-button-container {
          display: flex;
          margin-top: 16px;
          width: 100%;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        }

        &-darkButton {
          justify-content: flex-start;
          width: 100%;
          margin: 20px 0;
          max-width: 270px;
        }

        &-lightButton {
          color: ${colors.BORDER_GREY};
          justify-content: flex-start;
          width: 100%;
          max-width: 270px;
        }
      }

      &__arrow {
        width: 0;
        height: 0;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-top: 20px solid white;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        transform: translateX(160px);
      }
    }

    .genericButton {
      font-weight: 700;
      display: inline-block;
      padding: 13px 9px;
      border-width: 2px;
      border-style: solid;
      border-radius: 0;
      margin: 0;
      box-sizing: border-box;
      outline: none;
      text-align: center;
      line-height: inherit;
      cursor: pointer;

      @media (prefers-reduced-motion) {
        transition: 75ms color ease-in-out;
      }

      background-clip: border-box;
      box-shadow: 0 0 0 0;
      color: ${colors.WHITE};

      span {
        color: ${colors.WHITE};
      }

      &--black {
        border-color: ${colors.ONYX_GREY};
        background-color: ${colors.NERO_GREY};
        width: 100%;

        @media ${({ theme }) => media(theme).greaterThan('sm')} {
          width: 215px;
        }

        &:hover,
        &:active,
        &:focus {
          background-color: ${colors.ONYX_GREY};
        }
      }
    }

    .infoTip__container {
      margin-left: 4px;
      margin-top: -2px;
      position: relative;
      display: block;

      &--inline {
        display: inline;

        .disclaimer {
          position: relative;
        }
      }

      .disclaimer {
        color: ${colors.COLOR_GREY_60};

        &:hover,
        &:active,
        &:focus {
          color: ${colors.BLACK};
        }
      }

      .disclaimer--btn {
        font-size: ${({ theme }) => theme.font.size[2]};
        line-height: 16px;
        border: none;
        cursor: pointer;
        text-decoration: underline;
        vertical-align: initial;
        color: ${({ theme }) => theme.colors.content.primary};
        font-weight: ${({ theme }) => theme.overrides.font?.weight.regular};

        &:hover,
        &:active,
        &:focus {
          color: ${colors.BLACK};
        }
      }
    }

    .infoTip__title {
      color: ${colors.WHITE};
      background-color: ${colors.NERO_GREY};
      font-size: 16px;
      text-align: center;
      padding: 13px 0;
      display: block;

      @media ${({ theme }) => media(theme).greaterThan('sm')} {
        display: none;
      }
    }

    .is-hidden {
      display: none;
    }

    .modal__closeButton {
      color: ${colors.WHITE};
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      width: 45px;
      height: 45px;
      padding: 0;
      z-index: 52;
      font-size: 16px;
      outline: 0;
      border: none;

      @media ${({ theme }) => media(theme).greaterThan('sm')} {
        color: ${colors.BLACK};
      }
    }
  `,

  FreeDeliveryWrapper: styled.div`
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    font-size: 12px;
    margin-left: 2px;
    position: relative;
  `,

  Dialog: styled(Dialog)`
    &.product-usp-info-modal {
      height: inherit;
      top: 0;
      left: 0;
      transform: none;
    }

    .infoTip__title {
      color: ${colors.WHITE};
      background-color: ${colors.NERO_GREY};
      font-size: 16px;
      text-align: center;
      padding: 13px 0;
      display: block;
      line-height: 1em;
    }

    .infoTip__content {
      width: auto;
      min-width: auto;
      padding: 30px;
      background: ${colors.WHITE};
      font-style: normal;
      font-size: ${({ theme }) => theme.font.size[2]};
      color: ${colors.ACCESSIBILITY_GREY};

      .infoTip__content-button-container {
        margin-top: 16px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .genericButton--black {
          span {
            color: ${colors.WHITE};
          }

          color: ${colors.WHITE};
          border-color: ${colors.ONYX_GREY};
          background-color: ${colors.NERO_GREY};
          width: 100%;
        }

        .genericButton {
          font-size: ${({ theme }) => theme.font.size[2]};
          letter-spacing: 0.035em;
          line-height: 20px;
          font-weight: 700;
          display: inline-block;
          padding: 13px 9px;
          border-width: 2px;
          border-style: solid;
          box-sizing: border-box;
          text-align: center;
          cursor: pointer;
          transition: 75ms color ease-in-out;
          background-clip: border-box;

          @media (prefers-reduced-motion) {
            transition: none;
          }
        }
      }
    }
  `,
};

export const ProductUSPs = (): ReactElement => {
  const { locale, siteType } = useAppContext();
  const {
    product: { code, sizeInformation: staticSizeInformation, baseProduct },
  } = usePDPContext();
  const {
    dynamicProductData: { sizeInformation: dynamicSizeInformation },
  } = usePDPDynamicContext();
  const sizeInformation = dynamicSizeInformation ?? staticSizeInformation;
  const {
    gridValues: { gridValue1, gridValue2 },
  } = useSizeContext();
  const {
    configuration: { enablePdpDeliveryOptions },
  } = useStaticContext();
  const { isMobile } = useMediaQueryContext();

  const [modalOpened, setModalOpened] = useState(false);
  const [modalContent, setModalContent] = useState<string>('');
  const ref = useRef<HTMLDivElement>(null);

  let variantCode = code;

  if (sizeInformation?.sizeType === SizeType.NumericDouble && gridValue1 && gridValue2) {
    variantCode = `${baseProduct || code}-${gridValue1}${gridValue2}`;
  } else if (gridValue1) {
    variantCode = `${baseProduct || code}-${gridValue1}`;
  }

  const { error, data, previousData } = useUspsQuery({
    variables: {
      siteType,
      code: variantCode?.toUpperCase(),
      locale,
    },
    ssr: false,
    skip: gridValue1 === undefined || gridValue2 === undefined,
  });

  // use 'stale' previous data while new data is being loaded to prevent layout shifts and content flash
  const uspsString = data?.usps?.html ?? previousData?.usps?.html ?? '';

  const infoButtonClicked = useCallback(
    (e: Event) => {
      const target = e.target as HTMLElement;

      if (ref.current) {
        const infoTips = ref.current.querySelectorAll('.infoTip');

        infoTips.forEach(infoTip => {
          infoTip.classList.add('is-hidden');
        });
      }

      if (target) {
        if (isMobile) {
          const targetUspDisclaimerContainer = target.closest('.infoTip__container');
          const contents = Array.from(
            targetUspDisclaimerContainer?.querySelectorAll('.infoTip__title,.infoTip__content') ||
              []
          )
            .map(el => el.outerHTML)
            .join('');

          setModalContent(contents);
          setModalOpened(true);
        } else {
          const targetUspDisclaimerContainerTooltip = target.nextElementSibling;

          targetUspDisclaimerContainerTooltip?.classList.remove('is-hidden');
        }
      }
    },
    [isMobile]
  );

  useEffect(() => {
    const modalWrapperEl = document.querySelector('.product-usp-modal-wrapper');
    const observer = new MutationObserver(() => {
      const confirmBtn = document.querySelector('.product-usp-info-modal .js-confirmButton');
      const closeBtn: HTMLButtonElement | null = document.querySelector(
        '.product-usp-info-modal .close-dialog'
      );

      if (confirmBtn && closeBtn) {
        confirmBtn.addEventListener('click', () => {
          closeBtn.click();
        });
      }
    });

    if (isMobile && modalWrapperEl && modalOpened) {
      observer.observe(modalWrapperEl, { attributes: true, childList: true, subtree: true });
    }

    return () => observer.disconnect();
  }, [isMobile, modalOpened]);

  const hideInfoTip = useCallback((e: Event) => {
    const target = e.target as HTMLElement;

    if (target) {
      const targetUspDisclaimerContainer = target.closest('.infoTip');

      targetUspDisclaimerContainer?.classList.add('is-hidden');
    }
  }, []);

  const outsideClicked = useCallback((e: Event) => {
    if (ref.current && !ref.current.contains(e.target as Node)) {
      const infoTips = ref.current.querySelectorAll('.infoTip');

      infoTips.forEach(infoTip => {
        infoTip.classList.add('is-hidden');
      });
    }
  }, []);

  useEffect(() => {
    if (uspsString) {
      const uspDisclaimerContainer = document.getElementById('js-usp-placeholder');
      const uspDisclaimerContainerTooltip = uspDisclaimerContainer?.querySelectorAll('.infoTip');
      const uspDisclaimerContainerTooltipIcons = uspDisclaimerContainer?.querySelectorAll(
        '.icon-infocircle, .disclaimer--btn'
      );
      const uspDisclaimerButtons = uspDisclaimerContainer?.querySelectorAll('.genericButton');
      const uspCloseButtons = uspDisclaimerContainer?.querySelectorAll('.modal__closeButton');

      if (uspDisclaimerContainerTooltip?.length && uspDisclaimerContainerTooltipIcons?.length) {
        uspDisclaimerContainerTooltipIcons.forEach(uspDisclaimerContainerTooltipIcon => {
          uspDisclaimerContainerTooltipIcon.removeAttribute('id');
          uspDisclaimerContainerTooltipIcon.setAttribute('aria-label', 'usp-disclaimer');
          uspDisclaimerContainerTooltipIcon.addEventListener('click', e => {
            infoButtonClicked(e);
          });
        });

        uspDisclaimerButtons?.forEach(button => button.addEventListener('click', hideInfoTip));
        uspCloseButtons?.forEach(button => button.addEventListener('click', hideInfoTip));
      }

      document.addEventListener('click', outsideClicked);
    }

    return () => {
      const oldElement = document.getElementById('js-usp-placeholder');
      const newElement = oldElement?.cloneNode(true);

      if (newElement) {
        oldElement?.parentNode?.replaceChild(newElement, oldElement);
      }
    };
  }, [hideInfoTip, infoButtonClicked, outsideClicked, uspsString]);

  if (error && !uspsString && !enablePdpDeliveryOptions) {
    return <></>;
  }

  return (
    <>
      <S.Wrapper ref={ref} dangerouslySetInnerHTML={{ __html: uspsString }} data-cs-capture="" />

      {isMobile && (
        <div className="product-usp-modal-wrapper">
          {modalOpened && (
            <S.Dialog
              ariaLabel="product-usp-info"
              className="product-usp-info-modal"
              onClose={() => setModalOpened(false)}
              overlayColor="rgba(0,0,0,.1)"
            >
              {/* eslint-disable-next-line react/no-danger */}
              <div dangerouslySetInnerHTML={{ __html: modalContent }} />
            </S.Dialog>
          )}
        </div>
      )}
    </>
  );
};
