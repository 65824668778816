import { useState, useEffect } from 'react';
import type { ReactElement } from 'react';
import styled from 'styled-components';
import type { PDPBanner as BannerProp } from '../../../amplienceTypes/schemas/imported/pdp-banner-schema';
import { useMediaQueryContext } from '../../../utilities/context/dynamic/MediaQueryContext';
import { media, colors } from '../../shared/core/styles';
import { Button } from '../../shared/core/button/Button';
import { useAppContext } from '~/utilities/context/static/AppContext';

interface StyledTextProp {
  $textAlignment?: string;
  $color?: string;
  $textDecoration?: string;
  $fontSize?: number;
  $lineHeight?: number;
  $textTransform?: string;
  [k: string]: unknown;
}

const S = {
  BannerContainer: styled.div`
    position: relative;
  `,

  VideoBannerContainer: styled.div`
    position: relative;
    padding-top: 100%;

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      padding-top: 68%;
    }

    @media ${({ theme }) => media(theme).greaterThan('lg')} {
      padding-top: 44.9488%;
    }
  `,

  Iframe: styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `,

  ImageContent: styled.div<{ $desktopSrc: string; $tabletSrc: string; $mobileSrc: string }>`
    content: url('${({ $mobileSrc }) => $mobileSrc}');
    width: 100%;

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      content: url('${({ $tabletSrc }) => $tabletSrc}');
    }

    @media ${({ theme }) => media(theme).greaterThan('lg')} {
      content: url('${({ $desktopSrc }) => $desktopSrc}');
    }
  `,

  ContentMain: styled.div`
    position: absolute;
    bottom: 15%;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  `,

  Subtitle: styled.p<StyledTextProp>`
    text-align: ${({ $textAlignment }) => $textAlignment};
    color: ${({ $color }) => $color || colors.WHITE};
    text-decoration: ${({ $textDecoration }) => $textDecoration};
    font-size: ${({ $fontSize }) => $fontSize && `${$fontSize}px`};
    line-height: ${({ $lineHeight }) => $lineHeight && `${$lineHeight}px`};
    text-transform: ${({ $textTransform }) => $textTransform};
    margin-bottom: 32px;
  `,

  Buttons: styled.div`
    display: flex;
    justify-content: center;
    gap: 8px;
  `,
};

export const Banner = ({ backgroundImage, buttons, subTitle, vimeo }: BannerProp): ReactElement => {
  const { isMobile, isTablet } = useMediaQueryContext();
  const { amplienceMediaURL } = useAppContext();
  const [videoSrc, setVideoSrc] = useState<string>();

  useEffect(() => {
    // Vimeo Id
    const { desktop, tablet, mobile } = vimeo || {};

    if (isMobile) {
      setVideoSrc(mobile);
    } else if (isTablet) {
      setVideoSrc(tablet);
    } else {
      setVideoSrc(desktop);
    }
  }, [isMobile, isTablet, vimeo]);

  // Content Block Image
  const host = amplienceMediaURL;
  const desktopSrc = host + (backgroundImage?.images?.desktop?.name ?? '');
  const tabletSrc = host + (backgroundImage?.images?.tablet?.name ?? '');
  const mobileSrc = host + (backgroundImage?.images?.mobile?.name ?? '');
  const altText = backgroundImage?.images?.altText?.textValue;

  // Titles
  const subtitleComponent = subTitle?.text?.tag || 'h3';
  const subtitle = subTitle?.text?.textValue;
  const subtitleAlignment = subTitle?.text?.textAlignment;
  const subtitleDecoration = subTitle?.text?.textDecoration;
  const subtitleColor = subTitle?.text?.color;
  const subtitleFontsize = subTitle?.text?.fontSize;
  const subtitleLineHeight = subTitle?.text?.lineHeight;
  const subtitleTransform = subTitle?.text?.textTransform;

  return (
    <S.BannerContainer>
      {videoSrc ? (
        <S.VideoBannerContainer>
          <S.Iframe
            src={`https://player.vimeo.com/video/${videoSrc}?h=c2edf7f320&muted=1&autoplay=1&autopause=0&dnt=1&loop=1&background=1&quality=720p&app_id=122963`}
            frameBorder="0"
            title="video-banner"
            allow="autoplay; fullscreen; picture-in-picture"
            loading="lazy"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            fetchpriority="low"
          />
        </S.VideoBannerContainer>
      ) : (
        <S.ImageContent
          $desktopSrc={desktopSrc}
          $tabletSrc={tabletSrc}
          $mobileSrc={mobileSrc}
          title={altText}
        />
      )}

      <S.ContentMain>
        {subtitle && (
          <S.Subtitle
            as={subtitleComponent}
            $color={subtitleColor}
            $textAlignment={subtitleAlignment}
            $textDecoration={subtitleDecoration}
            $fontSize={subtitleFontsize}
            $lineHeight={subtitleLineHeight}
            $textTransform={subtitleTransform}
          >
            {subtitle}
          </S.Subtitle>
        )}
        <S.Buttons>
          {buttons &&
            buttons.map((button, i) => (
              <Button
                key={i}
                label={button.label?.textValue}
                href={button.link?.textValue}
                ordinal={button.ordinal}
              />
            ))}
        </S.Buttons>
      </S.ContentMain>
    </S.BannerContainer>
  );
};
