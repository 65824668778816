import type { ReactElement } from 'react';
import { useMemo } from 'react';
import styled from 'styled-components';

import type { ProductDetailInfo as ProductDetailInfoSchema } from '../../../amplienceTypes/schemas/imported/product-detail-info-schema';
import { useGalleryContext } from '~/utilities/context/dynamic/GalleryContext';
import { useAppContext } from '~/utilities/context/static/AppContext';
import { usePDPContext } from '~/utilities/context/static/PDPContext';

import { addProductToRecentlyViewedLocalStorage } from '~/utilities/helpers';
import { ProductDetailControl } from './productDetailControl/ProductDetailControl';
import { ProductImageGallery } from './productImageGallery/ProductImageGallery';
import { colors } from '~/components/shared/core/styles';

const S = {
  ProductDetailWrapper: styled.div`
    border-top: 1px solid ${colors.COLOR_GREY_200};
    position: relative;
    pointer-events: all;
  `,
};

export const ProductDetailInfo = (props: ProductDetailInfoSchema): ReactElement => {
  const { productImageGallery, productVideo } = props;
  const { country } = useAppContext();
  const { product } = usePDPContext();
  const { code } = product;
  const { styleVariantsSelectedIndex, productStyleVariantIndex, styleVariants } =
    useGalleryContext();

  if (product.url) {
    addProductToRecentlyViewedLocalStorage(country, product);
  }

  const productStyleVariantInfo = useMemo(
    () =>
      styleVariantsSelectedIndex !== productStyleVariantIndex && styleVariants
        ? styleVariants[styleVariantsSelectedIndex]
        : undefined,
    [styleVariants, styleVariantsSelectedIndex, productStyleVariantIndex]
  );

  return (
    <S.ProductDetailWrapper data-cs-capture="" data-code={code}>
      {productImageGallery?.content && productVideo && (
        <ProductImageGallery {...productImageGallery.content} productVideo={productVideo} />
      )}
      <ProductDetailControl {...props} productStyleVariantInfo={productStyleVariantInfo} />
    </S.ProductDetailWrapper>
  );
};
