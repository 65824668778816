import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { Svg } from './styles';
import { colors } from '../styles';

const MinusIconBold = ({
  color = colors.BLACK,
  hoverColor,
  className,
}: IconProps): ReactElement => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    x="0px"
    y="0px"
    width="100%"
    height="100%"
    $hoverColor={hoverColor}
    className={className}
  >
    <g fill={color}>
      <rect x="2" y="7" width="12" height="2" fill={color} />
    </g>
  </Svg>
);

export default MinusIconBold;
