import type { ReactElement, MouseEvent } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import dynamic from 'next/dynamic';
import { gsap } from 'gsap';
import { useTranslation } from 'next-i18next';
import type { ProductImageGallery2 } from '~/amplienceTypes/schemas/imported/product-image-gallery-schema';
import { media } from '../../../shared/core/styles';
import type { CarouselImageProperty } from './ProductImage';
import { ProductImage } from './ProductImage';
import { Arrow } from './Arrow';
import { DotNavigation } from './DotNavigation';

import { usePDPContext } from '~/utilities/context/static/PDPContext';
import { useGalleryContext } from '~/utilities/context/dynamic/GalleryContext';
import { useMediaQueryContext } from '~/utilities/context/dynamic/MediaQueryContext';
import { useLayoutDesignContext } from '~/utilities/context/dynamic/LayoutDesignContext';
import { useDataLayerContext } from '~/utilities/context/static/DataLayerContext';
import type { ProductVideo as ProductVideoSchema } from '../../../../amplienceTypes/schemas/imported/product-video-schema';
import type { ProductVideoProps } from './ProductVideo';
import { SiteType, VideoMode } from '~/utilities/graphql/codegen';
import { ThumbnailsWrapper } from './ThumbnailsWrapper';
import { useComponentSize } from '~/utilities/dom';
import { useAppContext } from '~/utilities/context/static/AppContext';

const ProductVideo = dynamic<ProductVideoProps>(
  () => import('./ProductVideo').then(mod => mod.ProductVideo),
  { ssr: false }
);

const Signings = dynamic(async () => (await import('../../signings/Signings')).Signings, {
  ssr: false,
});

export interface ProductImageGalleryHybrisProps {
  productVideo: {
    content: ProductVideoSchema;
    [k: string]: unknown;
  };
}

export type AnimationDirection = 'left' | 'right';

const S = {
  GridCarousel: styled.div`
    overflow: hidden;
    position: relative;
    height: 100%;
  `,
  GridCarouselInner: styled.div`
    width: 100%;
    height: 100%;
    position: relative;
  `,
  GridCarouselInnerItem: styled.div<{
    $isDarkImage: boolean;
    $hasStickyProductImageGallery?: boolean;
  }>`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: absolute;
    transform: translateX(calc(100% + var(--scrollbar-width)));

    &:first-child {
      transform: translateX(0);
    }
  `,
  MobileVideoPlayButton: styled.div`
    z-index: 20;
    position: absolute;
    text-align: center;
    width: 55px;
    bottom: 20px;
    left: 5px;
    display: block;

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      display: none;
    }
  `,
  SigningsWrapper: styled.div`
    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      position: relative;
    }
  `,
};

export interface ProductCarouselVideoViewProps extends ProductImageGallery2 {
  productImageList: CarouselImageProperty[];
  setZoomPanelScrollXCenter: (e: MouseEvent) => void;
  currentImageIndex: number;
  setCurrentImageIndex: (idx: number) => void;
}

export const ProductCarouselVideoView = ({
  autoSlide,
  arrowLeftV2,
  arrowRightV2,
  productVideo,
  productSignings,
  productImageList,
  setZoomPanelScrollXCenter,
  currentImageIndex,
  setCurrentImageIndex,
}: ProductCarouselVideoViewProps & ProductImageGalleryHybrisProps): ReactElement => {
  const { pushToDataLayer } = useDataLayerContext();
  const {
    product: { videos, code, shouldUseDarkBackgroundImgs = true },
  } = usePDPContext();
  const { galleryZoomIn, styleVariantsSelectedIndex, productStyleVariantIndex, styleVariants } =
    useGalleryContext();
  const { hasStickyProductImageGallery } = useLayoutDesignContext();

  const { siteType } = useAppContext();
  const isAafSite = siteType === SiteType.Aaf;

  const { isMobile, isTablet, isDesktop } = useMediaQueryContext();
  const [videoActive, setVideoActive] = useState<boolean>(false);
  const [timerStopped, stopTimer] = useState<boolean>(false);
  const [touchStartX, setTouchStartX] = useState<number>(0);
  const [autoplay, setAutoplay] = useState(false);
  const { t } = useTranslation('pdp', { keyPrefix: 'productImageGallery' });

  const galleryRef = useRef<HTMLDivElement>(null);
  const imageGalleryWrapperRef = useRef<HTMLDivElement>(null);
  const gsapQuery = gsap.utils.selector(galleryRef);

  const video = videos?.find(v => v?.mode === VideoMode.Landscape);

  const isAutoSlide = useMemo(
    () =>
      (isMobile && autoSlide?.mobile) ||
      (isTablet && autoSlide?.tablet) ||
      (isDesktop && autoSlide?.desktop) ||
      false,
    [autoSlide?.mobile, autoSlide?.tablet, autoSlide?.desktop, isMobile, isTablet, isDesktop]
  );

  const productStyleVariantInfo = useMemo(
    () =>
      styleVariantsSelectedIndex !== productStyleVariantIndex && styleVariants
        ? styleVariants[styleVariantsSelectedIndex]
        : undefined,
    [styleVariants, styleVariantsSelectedIndex, productStyleVariantIndex]
  );

  const styleVariant = useMemo(() => {
    if (productStyleVariantInfo?.images) {
      const { thumbnailAltText, xlarge, large } = productStyleVariantInfo.images;
      const productImage = {
        altText: thumbnailAltText ?? '',
        url: xlarge || large || '',
      };
      const newImage: CarouselImageProperty = {
        original: productImage,
        wide: productImage,
      };

      return newImage;
    }

    return null;
  }, [productStyleVariantInfo]);

  const { height: productDetailInfoHeight } = useComponentSize('.product-detail-info-wrapper', {
    condition: !hasStickyProductImageGallery,
  });

  useEffect(() => {
    if (
      !hasStickyProductImageGallery &&
      imageGalleryWrapperRef.current &&
      productDetailInfoHeight &&
      !isMobile
    ) {
      imageGalleryWrapperRef.current.style.maxHeight = `${productDetailInfoHeight}px`;
    }
  }, [productDetailInfoHeight, hasStickyProductImageGallery, isMobile]);

  const slideToNextImage = useCallback(
    (newIndex: number) => {
      if (currentImageIndex === newIndex) {
        return;
      }

      let nextIndex = newIndex;

      if (isMobile && galleryZoomIn && nextIndex === 2) {
        if (currentImageIndex < nextIndex && productImageList[nextIndex + 1]) {
          nextIndex = 3;
        } else {
          nextIndex = 0;
        }

        if (currentImageIndex > nextIndex) {
          nextIndex = 1;
        }
      }

      let direction: AnimationDirection = 'left';

      if (nextIndex < currentImageIndex) {
        if (
          currentImageIndex - nextIndex <=
          nextIndex + productImageList.length - currentImageIndex
        ) {
          direction = 'right';
        }
      } else if (
        currentImageIndex + productImageList.length - nextIndex <
        nextIndex - currentImageIndex
      ) {
        direction = 'right';
      }

      gsap.to(gsapQuery('.gallery-item')[nextIndex], {
        x: direction === 'left' ? '100%' : '-100%',
        duration: 0,
      });
      gsap.to(gsapQuery('.gallery-item')[currentImageIndex], {
        x: direction === 'left' ? '-100%' : '100%',
        duration: 0.6,
      });
      gsap.to(gsapQuery('.gallery-item')[nextIndex], {
        x: '0',
        duration: 0.6,
      });

      setCurrentImageIndex(nextIndex);

      if (isMobile) {
        if (nextIndex === 2) {
          setVideoActive(true);
          setAutoplay(true);
        } else {
          setAutoplay(false);
        }
      }
    },
    [isMobile, galleryZoomIn, productImageList, currentImageIndex, setCurrentImageIndex, gsapQuery]
  );

  useEffect(() => {
    const autoSlideToNextImg = () => {
      const nextIndex = (currentImageIndex + 1) % productImageList.length;

      slideToNextImage(nextIndex);
    };

    let timerId = 0;
    const delay = isMobile && video && currentImageIndex === 2 ? 18000 : 5000;

    if (isAutoSlide && !timerStopped) {
      timerId = window.setTimeout(autoSlideToNextImg, delay);
    }

    return () => {
      if (timerId !== 0) {
        window.clearTimeout(timerId);
      }
    };
  }, [
    currentImageIndex,
    isAutoSlide,
    productImageList.length,
    slideToNextImage,
    timerStopped,
    isMobile,
    video,
  ]);

  const onSlideToNextImage = async (
    nextIndex: number,
    el: 'thumbnail' | 'arrow',
    productCode: string
  ) => {
    stopTimer(true);
    slideToNextImage(nextIndex);

    pushToDataLayer({
      events: {
        category: el,
        action: `click ${el === 'arrow' ? 'arrow ' : ''}${nextIndex + 1}`,
        label: String(nextIndex + 1).padStart(3, `${shouldUseDarkBackgroundImgs ? 'M' : 'Z'}00`),
      },
      product: {
        materialNumber: productCode,
      },
      event: 'thumbnail-click',
    });
  };

  useEffect(() => {
    const refEl = galleryRef.current;
    const startHandler = (event: TouchEvent) => {
      if (galleryZoomIn) {
        return;
      }

      setTouchStartX(event?.changedTouches?.[0]?.pageX || NaN);
    };
    const endHandler = (event: TouchEvent) => {
      if (galleryZoomIn) {
        return;
      }

      const touchEndX = event?.changedTouches?.[0]?.pageX || NaN;

      if (!Number.isNaN(touchStartX) && !Number.isNaN(touchEndX)) {
        stopTimer(true);

        const delta = Math.abs(touchEndX - touchStartX);

        if (delta < 18) {
          return;
        }

        if (touchEndX > touchStartX) {
          const nextIndex =
            currentImageIndex === 0 ? productImageList.length - 1 : currentImageIndex - 1;

          slideToNextImage(nextIndex);
        } else if (touchEndX < touchStartX) {
          const prevIndex =
            currentImageIndex === productImageList.length - 1 ? 0 : currentImageIndex + 1;

          slideToNextImage(prevIndex);
        }
      }
    };

    if (refEl) {
      refEl.addEventListener('touchstart', startHandler, { passive: true });
      refEl.addEventListener('touchend', endHandler, { passive: true });
    }

    return () => {
      if (refEl) {
        refEl.removeEventListener('touchstart', startHandler);
        refEl.removeEventListener('touchend', endHandler);
      }
    };
  }, [
    galleryRef,
    touchStartX,
    currentImageIndex,
    slideToNextImage,
    productImageList.length,
    galleryZoomIn,
  ]);

  return (
    <>
      {productSignings?.content && (
        <S.SigningsWrapper>
          <Signings
            {...productSignings.content}
            productStyleVariantInfo={productStyleVariantInfo}
            showForWideImageOnly={false}
          />
        </S.SigningsWrapper>
      )}
      <S.GridCarousel data-qubit-id="image-gallery">
        <S.GridCarouselInner ref={galleryRef}>
          <>
            {productImageList?.map((productImage, index) =>
              isMobile && video && index === 2 ? (
                <S.GridCarouselInnerItem
                  className="gallery-item"
                  data-testid="image-hero"
                  key={index}
                  $isDarkImage={!!shouldUseDarkBackgroundImgs}
                  onClick={setZoomPanelScrollXCenter}
                  $hasStickyProductImageGallery={hasStickyProductImageGallery}
                >
                  <ProductVideo
                    {...productVideo?.content}
                    active={videoActive}
                    setActive={setVideoActive}
                    autoplay={autoplay}
                    video={video}
                  />
                </S.GridCarouselInnerItem>
              ) : (
                <S.GridCarouselInnerItem
                  className="gallery-item"
                  data-testid="image-hero"
                  key={index}
                  $isDarkImage={!!shouldUseDarkBackgroundImgs}
                  onClick={setZoomPanelScrollXCenter}
                  $hasStickyProductImageGallery={hasStickyProductImageGallery}
                >
                  {styleVariant ? (
                    <ProductImage lazyLoad={index !== currentImageIndex} {...styleVariant} />
                  ) : (
                    <ProductImage lazyLoad={index !== currentImageIndex} {...productImage} />
                  )}
                </S.GridCarouselInnerItem>
              )
            )}
          </>
        </S.GridCarouselInner>
        {isAafSite ? (
          <></>
        ) : (
          [
            { direction: -1, ariaLabel: arrowLeftV2 || t('arrowLeft') },
            { direction: 1, ariaLabel: arrowRightV2 || t('arrowRight') },
          ].map((entry, idx) => (
            <Arrow
              testId={`image-gallery-arrow-${entry.direction === -1 ? 'left' : 'right'}`}
              direction={entry.direction}
              key={idx}
              ariaLabel={entry.ariaLabel}
              galleryZoomIn={galleryZoomIn || false}
              clickHandler={() => {
                const nextIndex = Math.abs(
                  (productImageList.length + (currentImageIndex + 1 * entry.direction)) %
                    productImageList.length
                );

                onSlideToNextImage(nextIndex, 'arrow', code);
              }}
            />
          ))
        )}

        <DotNavigation
          galleryZoomIn={galleryZoomIn || false}
          currentImageIndex={currentImageIndex}
          clickHandlers={productImageList.map(
            // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
            (_, index) => _ => onSlideToNextImage(index, 'thumbnail', code)
          )}
        />
      </S.GridCarousel>
      <ThumbnailsWrapper
        code={code}
        galleryZoomIn={galleryZoomIn}
        onSlideToNextImage={onSlideToNextImage}
        styleVariant={!!styleVariant}
        currentImageIndex={currentImageIndex}
        productImageList={productImageList}
        videoClickHandler={() => setVideoActive(true)}
        hasVideo={!!video}
        videoActive={videoActive}
      />
      {video && videoActive && !isMobile && (
        <ProductVideo
          {...productVideo?.content}
          active={videoActive}
          autoplay={autoplay}
          setActive={setVideoActive}
          video={video}
        />
      )}
    </>
  );
};
