import type { ReactElement } from 'react';
import type { RuleSet } from 'styled-components';
import styled, { css } from 'styled-components';
import { usePDPContext } from '../../../../utilities/context/static/PDPContext';
import { useStaticContext } from '../../../../utilities/context/static/StaticContext';
import { ReturnFeeType, SiteType } from '../../../../utilities/graphql/codegen';
import { colors } from '../../../shared/core/styles';

const GstarIconReturns = css`
  line-height: 1.4;
  display: flex;
  align-items: center;
  padding-left: ${({ theme }) => theme.padding.md};

  &::before {
    content: '\\F185';
    font-family: gstar-icons;
    font-style: normal;
    font-size: ${({ theme }) => theme.font.size[4]};
    position: absolute;
    left: 0;
    color: ${({ theme }) => theme.colors.green['500']};
  }
`;

const AafIconReturns = css`
  padding-left: 25px;

  &::before {
    content: '\\F269';
    font-family: gstar-icons;
    font-style: normal;
    font-size: ${({ theme }) => theme.font.size[2]};
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 4px;
    color: ${colors.FREE_RETURN_FEE_GREEN};
  }
`;

const IconReturnsConfig: Record<SiteType, RuleSet> = {
  [SiteType.Gstar]: GstarIconReturns,
  [SiteType.Outlet]: GstarIconReturns,
  [SiteType.Employeeshop]: GstarIconReturns,
  [SiteType.Aaf]: AafIconReturns,
};

const GstarReturnFeeContainerStyle = css`
  padding: 0 0 ${({ theme }) => theme.padding['2xs']};
`;

const AafReturnFeeContainerStyle = css`
  padding: 4px 0 20px;
`;

const ReturnFeeContainerConfig: Record<SiteType, RuleSet> = {
  [SiteType.Gstar]: GstarReturnFeeContainerStyle,
  [SiteType.Outlet]: GstarReturnFeeContainerStyle,
  [SiteType.Employeeshop]: GstarReturnFeeContainerStyle,
  [SiteType.Aaf]: AafReturnFeeContainerStyle,
};

const S = {
  ReturnFeeContainer: styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    ${({ theme }) => ReturnFeeContainerConfig[theme.siteType]};
  `,

  Label: styled.p<{ $color: string }>`
    font-size: ${({ theme }) => theme.font.size[2]};
    font-weight: ${({ theme }) => theme.overrides.font?.weight.regular};
    line-height: 12px;
    position: relative;
    color: ${({ $color }) => $color};
    ${({ theme }) => IconReturnsConfig[theme.siteType]};
  `,
};

export const ProductReturnFees = (): ReactElement => {
  const {
    product: { returnFee },
  } = usePDPContext();
  const {
    configuration: {
      enableReturnFeeMDDC,
      enablePdpFreeReturnsMessage,
      enablePdpChargedReturnsMessage,
    },
  } = useStaticContext();

  const returnType = returnFee?.returnType;

  if (
    !returnFee ||
    !enableReturnFeeMDDC ||
    (returnType === ReturnFeeType.FreeReturns && !enablePdpFreeReturnsMessage) ||
    (returnType === ReturnFeeType.ChargedReturns && !enablePdpChargedReturnsMessage) ||
    (returnType === ReturnFeeType.CategoryChargedReturns && !enablePdpChargedReturnsMessage)
  ) {
    return <></>;
  }

  const labelColor =
    returnFee.color ||
    (returnType === ReturnFeeType.FreeReturns
      ? colors.FREE_RETURN_FEE_GREEN
      : colors.CHARGED_RETURN_FEE_RED);

  return (
    <S.ReturnFeeContainer>
      <S.Label
        data-testid={`product-return-fee-${returnType?.replace(/Returns/, '')}`}
        dangerouslySetInnerHTML={{ __html: returnFee.message ?? '' }}
        $color={labelColor}
      />
    </S.ReturnFeeContainer>
  );
};
