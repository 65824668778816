import gsap from 'gsap';
import { useCallback, useEffect, useRef } from 'react';
import type { ReactElement, ReactNode } from 'react';
import type { RuleSet } from 'styled-components';
import styled, { css } from 'styled-components';

import { useMediaQueryContext } from '../../../utilities/context/dynamic/MediaQueryContext';
import { useNavActionContext } from '../../../utilities/context/dynamic/NavActionContext';
import { useGalleryContext } from '~/utilities/context/dynamic/GalleryContext';
import { useComponentSize } from '../../../utilities/dom';
import { colors, media } from '../../shared/core/styles';
import { ProductAccordionItem, TRANSITION_DURATION } from './ProductAccordionItem';
import { SiteType } from '~/utilities/graphql/codegen';

const GstarAccordionsStyle = css`
  padding: 0;

  @media ${({ theme }) => media(theme).lessThan('sm')} {
    padding-top: ${({ theme }) => theme.padding.md};
  }
`;
const AafAccordionsStyle = css`
  padding: 0;
`;
const AccordionsConfig: Record<SiteType, RuleSet> = {
  [SiteType.Gstar]: GstarAccordionsStyle,
  [SiteType.Outlet]: GstarAccordionsStyle,
  [SiteType.Employeeshop]: GstarAccordionsStyle,
  [SiteType.Aaf]: AafAccordionsStyle,
};

const S = {
  Accordions: styled.div<{ $isSideNavOpened: boolean; $galleryZoomIn: boolean }>`
    --accordions-width: calc((100vw / 3) - (var(--side-nav-width) / 2));
    --min-width: 360px;

    width: var(--accordions-width);
    min-width: var(--min-width);
    position: relative;
    display: block;
    pointer-events: all;
    background-color: ${colors.WHITE};
    transition: width 250ms ease;
    box-sizing: content-box;
    z-index: 0;
    flex-grow: 1;
    ${({ theme }) => AccordionsConfig[theme.siteType]};

    @media (prefers-reduced-motion) {
      transition: none;
    }

    @media ${({ theme }) => media(theme).lessThan('lg')} {
      width: 100%;
    }

    @media ${({ theme }) => media(theme).greaterThan('lg')} {
      display: ${({ $galleryZoomIn }) => ($galleryZoomIn ? 'none' : 'table')};
    }
  `,
};

interface AccordionsProps {
  children?: ReactNode;
  name?: string;
}

export const ProductAccordions = ({ children }: AccordionsProps): ReactElement => {
  const { sideNavOpened } = useNavActionContext();
  const { isDesktop } = useMediaQueryContext();
  const { galleryZoomIn } = useGalleryContext();

  const productAccordionsRef = useRef<HTMLDivElement>(null);
  const parentHeightRef = useRef<number>(0);

  const { height: productDetailInfoHeight } = useComponentSize('.product-detail-info-wrapper');
  const { height: productAccordionsHeight } = useComponentSize(productAccordionsRef.current);

  useEffect(() => {
    parentHeightRef.current = productDetailInfoHeight + productAccordionsHeight;
  }, [productDetailInfoHeight, productAccordionsHeight]);

  const scrollIntoView = useCallback(
    (
      isOpen: boolean | undefined,
      accordionHeaderHeight: number,
      accordionContentHeight: number,
      accordionScrollTop: number,
      deeplink?: boolean
    ) => {
      const navHeight = 94;
      const newParentHeight =
        parentHeightRef.current + (isOpen ? accordionContentHeight : -accordionContentHeight);
      const scrollTopOverflow = accordionContentHeight + accordionScrollTop - window.innerHeight;

      // on desktop, scroll the page to the correct position when an accordion is opened
      // only if the opened accordion falls outside the viewport
      if (isOpen && (isDesktop || deeplink) && scrollTopOverflow > 0) {
        gsap
          .to(window, {
            duration: TRANSITION_DURATION / 1000,
            scrollTo: {
              y:
                accordionHeaderHeight + accordionContentHeight + navHeight > window.innerHeight
                  ? // accordion is taller than the viewport, scroll to the top of the accordion
                    window.scrollY + accordionScrollTop - navHeight
                  : // accordion is shorter than the viewport, scroll the accordion into view
                    window.scrollY + scrollTopOverflow + accordionHeaderHeight,
              autoKill: true,
            },
            ease: 'power1',
          })
          // prevent window from jumping
          .delay(TRANSITION_DURATION / 2 / 1000);
      }

      // on desktop, scroll the page to the correct position when an accordion is closed
      if (
        !isOpen &&
        isDesktop &&
        window.scrollY > 0 &&
        window.scrollY > newParentHeight - window.innerHeight
      ) {
        gsap.to(window, {
          duration: (TRANSITION_DURATION - 50) / 1000,
          scrollTo: {
            y: newParentHeight - window.innerHeight + navHeight,
            autoKill: true,
          },
          ease: 'power1',
        });
      }
    },
    [isDesktop]
  );

  return (
    <S.Accordions
      ref={productAccordionsRef}
      className="product-accordions"
      suppressHydrationWarning
      $isSideNavOpened={sideNavOpened}
      $galleryZoomIn={galleryZoomIn}
    >
      {((children as ReactElement[]) || []).map((child, i) => (
        <ProductAccordionItem
          {...(child?.props || {})}
          key={`${child?.key}-${i}`}
          scrollIntoView={scrollIntoView}
        >
          {child}
        </ProductAccordionItem>
      ))}
    </S.Accordions>
  );
};
