import type { ReactElement } from 'react';
import { useTranslation } from 'next-i18next';
import { S } from './styles';
import type { ProductDeliveryReturns as ProductDeliveryAndReturnsSchema } from '../../../amplienceTypes/schemas/imported/product-delivery-and-returns-schema';

import { usePDPContext } from '../../../utilities/context/static/PDPContext';

export const ProductDeliveryAndReturns = (
  schemaData: ProductDeliveryAndReturnsSchema
): ReactElement => {
  const {
    product: { isGiftCard },
  } = usePDPContext();

  const { t } = useTranslation('pdp', { keyPrefix: 'productSustainabilityTraceability' });

  const { title, delivery } = schemaData;

  if (isGiftCard) {
    return <></>;
  }

  return (
    <div data-testid="delivery-and-returns" id="delivery-and-returns">
      <S.AccordionTitle
        variant="h1"
        component="h2"
        data-testid="delivery-and-returns-title"
        data-accordion-title
      >
        {title || t('title')}
      </S.AccordionTitle>

      <S.Container dangerouslySetInnerHTML={{ __html: delivery?.html?.html || '' }} />
    </div>
  );
};
