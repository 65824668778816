import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { Svg } from './styles';
import { colors } from '../styles';

const PlusIconBold = ({ color = colors.BLACK, hoverColor, className }: IconProps): ReactElement => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    x="0px"
    y="0px"
    width="100%"
    height="100%"
    $hoverColor={hoverColor}
    className={className}
  >
    <g fill={color}>
      <path d="M14.25 8a.75.75 0 0 1-.75.75H8.75V13.5a.75.75 0 0 1-1.5 0V8.75H2.5a.75.75 0 0 1 0-1.5H7.25V2.5a.75.75 0 0 1 1.5 0V7.25H13.5a.75.75 0 0 1 .75.75Z" />
    </g>
  </Svg>
);

export default PlusIconBold;
