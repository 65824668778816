import type { ReactElement } from 'react';

import { SizeGuidesProvider } from '../../../../utilities/context/dynamic/SizeGuidesContext';
import { useSizeGuidesQuery } from '../../../../utilities/graphql/codegen';
import { useAppContext } from '../../../../utilities/context/static/AppContext';
import { MeasurementsComponent } from './MeasurementsComponent';

interface MeasurementsProps {
  title: string;
}

export const Measurements = ({ title, ...props }: MeasurementsProps): ReactElement => {
  const { locale, siteType } = useAppContext();
  let sizeGuidesData = {};
  const { loading, error, data } = useSizeGuidesQuery({
    variables: {
      siteType,
      locale,
    },
    ssr: false,
  });

  if (!error && !loading && data) {
    sizeGuidesData = data.sizeGuides;
  }

  return (
    <SizeGuidesProvider sizeGuidesData={sizeGuidesData}>
      <MeasurementsComponent title={title} sizeGuideLabelV2="" sizeGuideLink="" {...props} />
    </SizeGuidesProvider>
  );
};
