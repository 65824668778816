import type { ReactElement } from 'react';
import { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { setCookie } from 'cookies-next';
import type { RuleSet } from 'styled-components';
import type { ProductName as ProductNameSchema } from '../../../../amplienceTypes/schemas/imported/product-name-schema';
import { media } from '../../../shared/core/styles';
import { Typography } from '../../../shared/core/typography/Typography';
import type { StyleVariant } from '../../../../utilities/graphql/codegen';
import { CustomerType } from '../../../../utilities/graphql/codegen';
import { usePDPContext } from '../../../../utilities/context/static/PDPContext';
import { useLayoutDesignContext } from '../../../../utilities/context/dynamic/LayoutDesignContext';
import { useUserContext } from '../../../../utilities/context/dynamic/UserContext';
import { storageAvailable } from '../../../../utilities/helpers';
import { PDP_VIEWS } from '../../../../utilities/constants/localStorageKeys';
import { VTA } from '../../../../utilities/constants/cookies';
import { useStaticContext } from '../../../../utilities/context/static/StaticContext';
import { SiteType } from '~/utilities/graphql/codegen';

export interface ProductNameHybrisProps {
  productStyleVariantInfo?: StyleVariant | null;
}

const GStarProductNameStyles = css<{ $lowestPriceDisplay: string }>`
  font-weight: ${({ theme }) => theme.overrides.font?.weight.medium};
  font-size: ${({ theme }) => theme.font.size[4]};
  color: ${({ theme }) => theme.colors.content.primary};
  text-transform: capitalize;
  line-height: 1.4;

  @media ${({ theme }) => media(theme).greaterThan('sm')} {
    padding: 0 16px;
    line-height: 1.4;
    text-align: ${({ $lowestPriceDisplay }) =>
      $lowestPriceDisplay === 'NEVER' ? 'center' : 'left'};
  }

  @media ${({ theme }) => media(theme).greaterThan('lg')} {
    bottom: 0;
    padding: 0;
    font-size: ${({ theme }) => theme.font.size[6]};
    line-height: 1.4;
    text-align: left;
  }

  @media ${({ theme }) => media(theme).between('xs', 'lg')} {
    padding: 0;
    text-align: ${({ $lowestPriceDisplay }) =>
      $lowestPriceDisplay === 'NEVER' ? 'center' : 'left'};
  }
`;

const AafProductNameStyles = css<{ $lowestPriceDisplay: string }>`
  font-size: 14px;
  letter-spacing: 0.7px;
  text-align: left;
  padding: 0;
`;
const GStarProductNameWrapperStyles = css<{ $lowestPriceDisplay: string }>`
  margin-top: 0;
  margin-bottom: 0;

  @media ${({ theme }) => media(theme).greaterThan('lg')} {
    margin-bottom: 0;
  }
`;
const AafProductNameWrapperStyles = css<{ $lowestPriceDisplay: string }>`
  margin-top: 12px;
  margin-bottom: 8px;

  @media ${({ theme }) => media(theme).greaterThan('lg')} {
    margin-bottom: 10px;
    min-height: 20px;
  }
`;

const ProductNameConfig: Record<SiteType, RuleSet<{ $lowestPriceDisplay: string }>> = {
  [SiteType.Gstar]: GStarProductNameStyles,
  [SiteType.Outlet]: GStarProductNameStyles,
  [SiteType.Employeeshop]: GStarProductNameStyles,
  [SiteType.Aaf]: AafProductNameStyles,
};
const ProductNameWrapperConfig: Record<SiteType, RuleSet<{ $lowestPriceDisplay: string }>> = {
  [SiteType.Gstar]: GStarProductNameWrapperStyles,
  [SiteType.Outlet]: GStarProductNameWrapperStyles,
  [SiteType.Employeeshop]: GStarProductNameWrapperStyles,
  [SiteType.Aaf]: AafProductNameWrapperStyles,
};

const S = {
  ProductNameWrapper: styled.div<{
    $hasStickyProductImageGallery?: boolean;
    $lowestPriceDisplay: string;
  }>`
    position: relative;
    display: block;
    height: auto;

    @media ${({ theme }) => media(theme).greaterThan('lg')} {
      margin-top: ${({ $hasStickyProductImageGallery }) =>
        $hasStickyProductImageGallery ? '0' : '20px'};
    }
    ${({ theme }) => ProductNameWrapperConfig[theme.siteType]}
  `,
  ProductName: styled(Typography)<{ $lowestPriceDisplay: string }>`
    overflow: hidden;
    padding: 0 12px;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 30px;

    ${({ theme }) => ProductNameConfig[theme.siteType]}
  `,
};

export const ProductName = ({
  htmlTag,
  productStyleVariantInfo,
}: ProductNameHybrisProps & ProductNameSchema): ReactElement => {
  const {
    product: { name, subTargetAudience },
  } = usePDPContext();
  const { hasStickyProductImageGallery } = useLayoutDesignContext();
  const { user } = useUserContext();

  const {
    configuration: { lowestPriceDisplay },
  } = useStaticContext();

  useEffect(() => {
    if (user !== undefined && user?.customerType !== CustomerType.Registered && subTargetAudience) {
      const initialViews = {
        boys: 0,
        girls: 0,
        men: 0,
        women: 0,
      };

      if (storageAvailable('localStorage')) {
        const localPDPViews = localStorage.getItem(PDP_VIEWS);
        const views = localPDPViews ? JSON.parse(localPDPViews) : initialViews;

        Object.keys(views).some(key => {
          if (key === subTargetAudience) {
            const viewedNumber = parseInt(views[key], 10) + 1;

            if (viewedNumber >= 3) {
              setCookie(VTA, subTargetAudience, { maxAge: 60 * 60 * 24 * 365 });
              localStorage.setItem(PDP_VIEWS, JSON.stringify(initialViews));
            } else {
              views[key] = viewedNumber;
              localStorage.setItem(PDP_VIEWS, JSON.stringify(views));
            }

            return true;
          }

          return false;
        });
      }
    }
  }, [user, subTargetAudience]);

  return (
    <S.ProductNameWrapper
      data-testid="summary-product-name"
      $hasStickyProductImageGallery={hasStickyProductImageGallery}
      $lowestPriceDisplay={lowestPriceDisplay || 'NEVER'}
    >
      <S.ProductName
        $lowestPriceDisplay={lowestPriceDisplay || 'NEVER'}
        component={htmlTag}
        variant="h1"
        data-cs-capture=""
      >
        {productStyleVariantInfo?.name || name}
      </S.ProductName>
    </S.ProductNameWrapper>
  );
};
