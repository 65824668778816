import type { FC } from 'react';
import styled from 'styled-components';
import type { FlexProps } from '~/components/shared/core/flex/type';
import { getPaddingSize } from '~/core/theme/utils';
import type { ThemeObject } from '../../../../../styled-components';

const StyledFlex = styled.div<FlexProps>`
  display: flex;
  flex-direction: ${({ $flexDirection }) => $flexDirection || 'row'};
  flex-wrap: ${({ $flexWrap }) => $flexWrap || 'nowrap'};
  justify-content: ${({ $justifyContent }) => $justifyContent || 'flex-start'};
  align-items: ${({ $alignItems }) => $alignItems || 'stretch'};
  gap: ${({ $gap, theme }) => ($gap ? theme.size[$gap as keyof ThemeObject['size']] || $gap : '0')};

  ${({ padding, p, theme }) =>
    padding || p ? `padding: ${getPaddingSize(padding || p, theme)}` : ''};
  ${({ paddingLeft, pl, theme }) =>
    paddingLeft || pl ? `padding-left: ${getPaddingSize(paddingLeft || pl, theme)}` : ''};
  ${({ paddingRight, pr, theme }) =>
    paddingRight || pr ? `padding-right: : ${getPaddingSize(paddingRight || pr, theme)}` : ''};
  ${({ paddingBottom, pb, theme }) =>
    paddingBottom || pb ? `padding-bottom: ${getPaddingSize(paddingBottom || pb, theme)}` : ''};
  ${({ paddingTop, pt, theme }) =>
    paddingTop || pt ? `padding-top: ${getPaddingSize(paddingTop || pt, theme)}` : ''};
`;

export const Flex: FC<FlexProps> = ({ children, ...props }) => (
  <StyledFlex {...props}>{children}</StyledFlex>
);
