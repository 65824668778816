import type { ThemeObject } from '../../../styled-components';

const path = <T>(keys: Array<string>, obj: T) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  keys.reduce((xs: any, x: string) => xs?.[x] ?? null, obj);

export const getThemeSize = (size: string | undefined, theme: ThemeObject, defaultValue = '0') => {
  if (!size) {
    return defaultValue;
  }

  return theme.size[size as keyof typeof theme.size] || size;
};

export const getThemeColor = (
  color: string | undefined,
  theme: ThemeObject,
  defaultValue = 'inherit'
) => {
  if (!color) {
    return defaultValue;
  }

  const isColorGroup = color.includes('.');

  if (isColorGroup) {
    return path<ThemeObject['colors']>(color.split('.'), theme.colors) || defaultValue;
  }

  return theme.colors[color as keyof typeof theme.colors] || color;
};

export const getPaddingSize = (
  padding: string | undefined,
  theme: ThemeObject,
  defaultValue = '0'
) => {
  if (!padding) {
    return defaultValue;
  }

  return (
    theme.padding[padding as keyof ThemeObject['padding']] ||
    theme.size[padding as keyof ThemeObject['size']] ||
    padding
  );
};
