import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors, spacing } from '../../shared/core/styles';
import { Typography } from '../../shared/core/typography/Typography';
import { convertCESTStringToDate } from '../../../utilities/parsers';
import type { ProductCountdownTimer } from '../../../amplienceTypes/schemas/imported/product-countdown-timer-schema';
import { REMAINING_TIME_VARIABLE } from '../../../utilities/constants/amplienceVariables';

const S = {
  CountdownTimer: styled(Typography)`
    padding: 2px 0;
    margin: -${spacing.XXXS} 0 ${spacing.XXXS} 0;
    text-align: center;
    color: ${colors.FIORD_GREY};
    font-weight: bold;
    background: ${colors.COLOR_GREY_200};
    font-size: 12px;
    line-height: 16px;
  `,
};

interface CountDownTimerProps extends ProductCountdownTimer {
  salesDiscountsEndDate?: string | null;
}

export const CountdownTimer = ({
  message,
  salesDiscountsEndDate,
}: CountDownTimerProps): ReactElement => {
  const zeroTime = '00:00:00';
  const [remainingTime, setRemainingTime] = useState(zeroTime);
  const [isVisible, setIsVisible] = useState(false);

  const addZero = (num: number): string => num.toString().padStart(2, '0');

  useEffect(() => {
    if (!salesDiscountsEndDate) {
      return;
    }

    const calcRemainingTime = () => {
      const endTime = convertCESTStringToDate(salesDiscountsEndDate);

      if (endTime) {
        const diff = Math.floor((endTime.getTime() - Date.now()) / 1000);
        const hour = Math.floor(diff / 3600);
        const minute = Math.floor(diff / 60) % 60;
        const second = Math.floor(diff % 60);

        if (diff >= 0) {
          setRemainingTime(`${addZero(hour)}:${addZero(minute)}:${addZero(second)}`);
          setIsVisible(true);
          setTimeout(calcRemainingTime, 1000);
        } else {
          setRemainingTime(zeroTime);
        }
      }
    };

    calcRemainingTime();
  }, [salesDiscountsEndDate]);

  return isVisible ? (
    <S.CountdownTimer
      component="div"
      variant="body"
      testId="summary-countdown-timer"
      data-cs-capture=""
    >
      {message ? message.replace(REMAINING_TIME_VARIABLE, remainingTime) : remainingTime}
    </S.CountdownTimer>
  ) : (
    <></>
  );
};
